import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
/**
 * Trigger only when component unmount
 */
export default function useUnmount(callback) {
  var _React$useState = React.useState(false),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    enabled = _React$useState2[0],
    setEnabled = _React$useState2[1];
  React.useLayoutEffect(function () {
    return function () {
      if (enabled) {
        callback();
      }
    };
  }, [enabled]);
  React.useLayoutEffect(function () {
    setEnabled(true);
  }, []);
}